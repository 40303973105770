import React, { FC } from 'react'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import styles from './StickyBar.module.scss'
import { A } from 'components/basic/A'
import { P } from 'components/basic/P'
import { Heading } from 'components/basic/Heading'
import { RootStore } from 'stores'
import { useHeaderHeightObserver } from 'components/_hooks/useHeaderHeightObserver'
import { PriceManager } from 'prices/priceManager'
import { regionIsEU } from 'utils/internationalization'
import { useRouter } from 'next/dist/client/router'

export interface StickyBarProps {
	productTitle: string
	subheading: string
	additionalInfo?: string
	ctaText?: string
	shopUrl: string
	className?: string
	whiteBg?: boolean
	anchorLink?: boolean
	hide?: boolean
}

export const StickyBar: FC<StickyBarProps> = (props) => {
	const headerHeight = useHeaderHeightObserver()
	const router = useRouter()

	if (router.query.utm_source === 'app') {
		return null
	}

	return (
		<div
			className={cx(styles.sticky_bar, props.className, { white: props.whiteBg }, { [styles.hide]: props.hide })}
			id="sticky-bar"
			style={{ top: `${headerHeight || 0}px`, opacity: headerHeight ? 1 : 0 }}
		>
			<div className={styles.left}>
				<Heading
					type="h6"
					title={props.productTitle}
				/>
				<div className={styles.mobile_pricing_container}>
					<P
						paragraphClass={styles.subheading}
						paragraphs={[props.subheading, props.additionalInfo]}
					/>
				</div>
			</div>
			<div className={styles.right}>
				<div className={styles.desktop_pricing_container}>
					<P
						paragraphClass={styles.subheading}
						paragraphs={[props.subheading, props.additionalInfo ? props.additionalInfo : null]}
					/>
				</div>
				<div>
					{props.anchorLink ? (
						<a
							href={props.shopUrl}
							className={cx(styles.cta_btn, 'btn-lnk')}
							id="cover-lp-sticky-bar-cta"
						>
							<span>{props.ctaText ? props.ctaText : 'Shop now'}</span>
						</a>
					) : (
						<A
							href={props.shopUrl}
							className={cx(styles.cta_btn, 'btn-lnk')}
							id="cover-lp-sticky-bar-cta"
						>
							{props.ctaText ? props.ctaText : 'Shop now'}
						</A>
					)}
				</div>
			</div>
		</div>
	)
}

StickyBar.defaultProps = {
	productTitle: 'Pod 3 Cover',
	additionalInfo: '',
	shopUrl: '/product/pod-cover',
}

interface LiveStickyBarProps extends Omit<StickyBarProps, 'payNowPrice' | 'financingPrice' | 'subheading'> {
	rootStore?: RootStore
	hideFinancing?: boolean
	podOrCover: 'pod' | 'cover' | 'upgrade'
	anchorLink?: boolean
	hide?: boolean
	noExpire?: boolean
}

class LiveStickyBarComponent extends React.Component<LiveStickyBarProps> {
	getHolidaySubheading = () => {
		const { priceStore, settingsStore } = this.props.rootStore!
		const podDiscount = priceStore.totalProductDiscountNumberFormatted
		const coverDiscount = priceStore.pod4UltraDiscountFormatted
		const currentRegion = settingsStore.currentRegion

		let discount = ''
		if (this.props.podOrCover === 'pod') {
			discount = podDiscount
		}
		if (this.props.podOrCover === 'cover') {
			discount = coverDiscount
		}
		if (this.props.podOrCover === 'upgrade') {
			discount = PriceManager.formatPriceToCurrencyNoDecimal(500, priceStore.currency)
		}
		if (currentRegion === 'au' || currentRegion === 'uk' || regionIsEU(currentRegion)) {
			discount = coverDiscount
		}

		let saleEndDate = settingsStore.settings.endDate! as string

		if (saleEndDate) {
			const subtract1Day = (d: Date) => {
				const secondsInDay = 24 * 60 * 60 * 1000
				d.setTime(d.getTime() - secondsInDay)
				return d
			}

			let endDate = new Date(saleEndDate)
			endDate = subtract1Day(endDate)

			const p = endDate.toDateString().split(' ')
			saleEndDate = p[1] + ' ' + parseInt(p[2])
		}

		if (discount && (this.props.noExpire || !saleEndDate)) {
			return [`${settingsStore.settings.stickybartext}`, discount]
		}
		if (saleEndDate) {
			// return [`${settingsStore.settings.stickybartext} ends ${saleEndDate}`, discount]
			return [`${settingsStore.settings.stickybartext}`, discount]
		} else {
			return ['', discount]
		}
	}

	getSubheading = () => {
		const { priceStore } = this.props.rootStore!

		let payNowPrice = priceStore.coverPriceFormatted
		let monthlyPrice = priceStore.coverMonthlyPriceFormatted
		let months = PriceManager.getAffirmMaxMonths(priceStore.coverComparePriceNumber * 100)

		if (this.props.podOrCover === 'pod') {
			payNowPrice = priceStore.podPriceFormatted
			monthlyPrice = priceStore.podMonthlyPriceFormatted
			months = PriceManager.getAffirmMaxMonths(priceStore.podComparePriceNumber * 100)
		}

		let subheading = `From ${payNowPrice}`
		if (!this.props.hideFinancing && monthlyPrice) {
			subheading += `<br/> or as low as ${monthlyPrice} for ${months} mo`
		}
		return subheading
	}

	componentDidMount() {
		const { settingsStore } = this.props.rootStore!
		const stickyBarHeight = document.getElementById('sticky-bar')!.clientHeight || document.getElementById('sticky-atc')?.clientHeight || 0
		settingsStore.setCoverStickyHeight(stickyBarHeight)
	}

	componentWillUnmount() {
		const { settingsStore } = this.props.rootStore!
		settingsStore.setCoverStickyHeight(0)
	}

	render() {
		const subheading = this.getHolidaySubheading()[0]
		const cta = 'Shop now'

		return (
			<StickyBar
				{...this.props}
				subheading={subheading}
				whiteBg={false}
				ctaText={cta}
			/>
		)
	}
}

export const LiveStickyBar = inject('rootStore')(observer(LiveStickyBarComponent))
