import { FC } from 'react'
import styles from './CardSelectionItem.module.scss'
import { CardSelectionItemProps, StandardCardSelectionItemProps } from './types'
import { Icon } from 'components/Phantom/Icon'
import cx from 'classnames'
import { getContrastRatioHex } from 'components/_utils/colorUtils'

export const CardSelectionItem: FC<CardSelectionItemProps> = (props) => {
	const { highlightColor = '#0038FF', highlightTag, selected, error } = props

	const boxShadowColor = error ? '#df1c2f' : selected ? highlightColor : '#d3d3d3'
	const boxShadowWidth = selected ? '2px' : '1px'

	return (
		<div
			className={cx(styles.container, { [styles.container_selected]: selected })}
			style={{
				boxShadow: `${boxShadowColor} 0 0 0 ${boxShadowWidth} inset`,
			}}
		>
			<RenderedContent {...props} />
			<HighlightTag
				color={highlightColor}
				tag={highlightTag}
			/>
		</div>
	)
}

const HighlightTag = (props: { color: string; tag: string }) => {
	// Sets the text color to white if the contrast ratio is greater than 4.5, otherwise black
	const textColor = getContrastRatioHex(props.color, '#FFFFFF') > 4.5 ? '#FFFFFF' : '#000000'
	if (!props.tag) return null
	return (
		<span
			style={{
				backgroundColor: props.color,
				color: textColor,
			}}
			className={styles.highlight_tag}
		>
			{props.tag}
		</span>
	)
}

const RenderedContent = (props: CardSelectionItemProps) => {
	switch (props.type) {
		case 'standard':
			return <StandardCardSelectionItem {...props.data} />
		default:
			return null
	}
}

export const StandardCardSelectionItem: FC<StandardCardSelectionItemProps> = (props) => {
	const { title, subtitleTop, subtitleMedium, subtitle, badge, subtitleSmallest } = props
	const isEnhancedPlanCustomization = title === 'Enhanced'
	const checklist = isEnhancedPlanCustomization ? ['Everything in Standard, plus:'] : props.checklist

	const isPodCard = title === 'Pod 3' || title === 'Pod 4' || title === 'Pod 4 Ultra'
	const v_align = title === 'No leg kit'

	return (
		<div className={cx(styles.standard_card, { [styles.pod_card]: isPodCard }, { [styles.v_align]: v_align })}>
			{badge && <span className={styles.badge}>{badge}</span>}
			<div className={styles.standard_titles}>
				<p className={cx(styles.standard_title, 'title')}>{title}</p>
				<p className={cx(styles.standard_subtitle_small, 'subtitle_small')}>{subtitleTop}</p>
				<p className={cx(styles.standard_subtitle_medium, 'subtitle_medium')}>{subtitleMedium}</p>
				<p className={cx(styles.standard_subtitle, 'subtitle')}>{subtitle}</p>
				{subtitleSmallest && <p className={cx(styles.standard_subtitle_smallest, 'subtitle_smallest')}>{subtitleSmallest}</p>}
			</div>

			{checklist?.length > 0 && (
				<ul className={styles.checklist}>
					{checklist.map((item, index) => (
						<li key={index}>
							<Icon
								name={'CheckDark'}
								color={'#999999'}
								size={16}
								className={isEnhancedPlanCustomization ? 'hide_xs' : ''}
							/>
							{item}
						</li>
					))}
				</ul>
			)}
			{isEnhancedPlanCustomization && (
				<div className={styles.enhanced_plan_banner}>
					<Icon
						name={'ShieldCheckLight'}
						color={'#1862FF'}
						size={24}
					/>
					<span>Peace of mind with extended warranty</span>
				</div>
			)}
		</div>
	)
}
