import { action, makeObservable, observable } from 'mobx'
import * as eventNames from 'timerevents/eventNames'
import { RootStore } from 'stores'
import ModalEvent from 'timerevents/EmailCapModalEvent'
import { amExitIntentModalShown } from 'events/amplitude'
import { setPageScroll } from 'utils'

export default class ModalStore {
	constructor(private readonly rootStore: RootStore) {
		makeObservable(this)
		this.modalEvent = new ModalEvent()
	}

	@observable emailCapModalVisible = false
	@observable showEmailCapModalShortcut = false
	@observable assistedSalesModalVisible = false
	@observable referralWelcomeModalVisible = false
	@observable localizeModalVisible = false
	@observable showQuizEcap = false
	@observable showExitIntent = false
	@observable exitIntentCount = 0
	@observable modalEvent: ModalEvent

	@action displayEmailCapModal = () => {
		// here set and check session storage to prevent showing the same modal
		if (!sessionStorage.getItem(eventNames.emailCapModalTriggerDone) && sessionStorage.getItem('hide_ecap') !== 'true') {
			this.emailCapModalVisible = true
			this.showEmailCapModalShortcut = false
			setPageScroll(false)
		}
	}

	@action toggleQuizEcap = (flag: boolean) => {
		this.showQuizEcap = flag
	}

	@action dismissQuizEcap = () => {
		this.showQuizEcap = false
		localStorage.setItem('quizEcapDone', 'true')
	}

	@action forcedisplayEmailCapModal = () => {
		this.emailCapModalVisible = true
		this.showEmailCapModalShortcut = false
		setPageScroll(false)
	}

	@action toggleExitIntenEcap = (flag: boolean) => {
		if (this.exitIntentCount < 1) {
			this.emailCapModalVisible = flag
			this.showExitIntent = flag
			this.exitIntentCount += 1

			if (flag) {
				setPageScroll(false)
				amExitIntentModalShown()
			}
		}
	}

	@action hideEmailCapModal = () => {
		this.emailCapModalVisible = false
		this.showExitIntent = false
		this.showEmailCapModalShortcut = true
		setPageScroll(true)
		// this.displayTertiaryEmailCapModal()
	}

	@action forceDisplayEmailCapModalShortcut = () => {
		this.emailCapModalVisible = false
		this.showEmailCapModalShortcut = true
	}

	@action preventDisplayEmailCapModalShortcut = () => {
		this.showEmailCapModalShortcut = false
		sessionStorage.setItem(eventNames.emailCapShortcutButtonTriggerDone, 'true')
	}

	@action toggleLocalizeModal = () => {
		this.localizeModalVisible = !this.localizeModalVisible
	}

	@action displayLocalizeModal = () => {
		this.localizeModalVisible = true
	}
}
