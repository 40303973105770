import { getVisibleAmountPercentage } from 'components/_utils/boundingBoxHelpers'
import { SelectorKey } from 'components/Phantom/_shop/Selectors'

export const findCurrentSection = () => {
	const sections = document.querySelectorAll('[id^="shop-section-"]')

	let mostInViewIndex = 0
	let mostInView = 0

	for (let i = 0; i < sections.length; i++) {
		const section = sections[i]
		const index = Number.parseInt(section.id.replace('shop-section-', ''))

		const percentInView = getVisibleAmountPercentage(section)

		if (percentInView > mostInView) {
			mostInView = percentInView
			mostInViewIndex = index
		}
	}

	if (typeof window !== 'undefined' && window.scrollY === 0) {
		// If we're at the top of the page, return the first section
		// The reason this isn't automatic is that there are some screen-sizes that will have
		// the second section take precedence over the first even if the user is at the top of the page.
		return 0
	}

	return mostInViewIndex
}

export const findClosestSelector = (selectors: SelectorKey[]) => {
	if (typeof window === 'undefined') return selectors[0]

	const percentages = selectors.map((selector) => {
		const elementID = `selector-${selector}`
		const element = document.getElementById(elementID)
		if (!element) return 0

		return getVisibleAmountPercentage(element)
	})

	const largestIndex = percentages.indexOf(Math.max(...percentages))

	return selectors[largestIndex]
}
