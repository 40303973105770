import { FC, useEffect, useState } from 'react'
import { useScrollDirection } from 'components/_hooks/useScrollDirection'
import cx from 'classnames'
import styles from 'components/NavBar/NavBar.module.scss'
import { A } from 'components/basic/A'
import * as React from 'react'
import { Countdown } from './Countdown'
import { Settings } from 'react-slick'
import { SlickSlider } from 'components/SlickSlider'
import { RegionShort } from 'utils/internationalization'
import { amClosedPromoBar } from 'events/amplitude'
import { ClientOnly } from 'components/ClientOnly'
import { scrollLocked } from 'utils/jss'
import { close, Icon } from 'components/Icon'

interface PromoBarProps {
	promo_bar_text?: string
	promo_bar_href?: string
	promo_bar_date?: Date | string
	hidePromoBar?: boolean
	path?: string
	showTimer: boolean
	region: RegionShort
	saleName: string
	updatedHeight: () => void
}

const settings: Settings = {
	slidesToShow: 1,
	speed: 400,
	variableWidth: true,
	centerMode: false,
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false,
	draggable: true,
}

const filterPromoText = (promo_bar_text: string, saleName: string) => {
	const regexyp = new RegExp(`${saleName}: Up to .+ off`, 'gm')
	return promo_bar_text.replace(' exclusive:', ':').replace(' the Pod', '')
}

export const PromoBar: FC<PromoBarProps> = (props) => {
	const [mounted, setMounted] = useState(props.region !== '')
	const [doingAnimation, setDoingAnimation] = useState(false)
	const [usableScrollDirection, setUsableScrollDirection] = useState('')
	const scrollDirection = useScrollDirection(75)

	// Uses this FSM https://docs.google.com/drawings/d/1Phxpme_Iy4BvNSIk4vU1eti12jL2nz06oCM9x1agZPI/edit?usp=sharing
	useEffect(() => {
		// Don't close promo bar on tests, it causes weird misalignment issues
		if (typeof window !== 'undefined' && (window.location.search.includes('automated=true') || sessionStorage.getItem('automated') === 'true' || scrollLocked)) {
			return
		}

		if (!doingAnimation && usableScrollDirection !== scrollDirection) {
			setDoingAnimation(true)
			setUsableScrollDirection(scrollDirection)
		}
	}, [scrollDirection, doingAnimation])

	useEffect(() => {
		props.updatedHeight()
	}, [doingAnimation])

	const handleCloseClick = () => {
		if (typeof window !== 'undefined' && window.sessionStorage.getItem('closedPromoBar') === 'true') {
			setDoingAnimation(true)
			window.sessionStorage.removeItem('closedPromoBar')
		} else {
			amClosedPromoBar()
			window.sessionStorage.setItem('closedPromoBar', 'true')
			setUsableScrollDirection(scrollDirection)
		}
		setDoingAnimation(true)
	}

	const promoStyle = {
		display: props.hidePromoBar ? 'none' : 'inherit',
	}

	const main_item = {
		src: '',
		alt: '',
		component: (
			<div className={styles.main_item}>
				{props.promo_bar_href ? (
					<A
						href={props.promo_bar_href}
						id={'promo-bar-link'}
						className={styles.promo_text}
					>
						<span
							className={styles.promo_inner_text_desktop}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : props.promo_bar_text }}
						/>
						<span
							className={styles.promo_inner_text_mobile}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : filterPromoText(props.promo_bar_text, props.saleName) }}
						/>
					</A>
				) : (
					<>
						<p
							className={cx(styles.promo_text, styles.promo_inner_text_desktop)}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : props.promo_bar_text }}
						/>
						<p
							className={cx(styles.promo_text, styles.promo_inner_text_mobile)}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : filterPromoText(props.promo_bar_text, props.saleName) }}
						/>
					</>
				)}
				{props.showTimer && (
					<Countdown
						date={props.promo_bar_date}
						minDaysLeftToShow={1}
					/>
				)}
			</div>
		),
	}

	let items =
		(props.region === '' || props.region === 'ca') && !props.path?.includes('/product/the-base')
			? [
					main_item,
					{
						src: '',
						alt: '',
						component: (
							<A
								className={styles.promo_text}
								href={'/financing'}
								id={'promo-bar-financing-link'}
							>
								Financing starting from 0% APR with $0 down
							</A>
						),
					},
			  ]
			: [main_item]
	//don't show any usual promos on this page
	if (props.path?.startsWith('/bed-cooling-hsa') || (typeof window !== 'undefined' && props.path?.includes('/pod4-member') && localStorage.getItem('redirectToBase') === 'true')) {
		items = []
	}
	if (props.region === '' && (props.path?.startsWith('/bed-cooling-hsa') || (!props.path?.includes('/product/the-base') && !props.path?.includes('/pod4-member')))) {
		items.push({
			src: '',
			alt: '',
			component: props.path?.startsWith('/truemed') ? (
				<p className={styles.promo_text}>
					<span style={{ paddingRight: '0.5ch' }}>Save up to 40% with HSA/FSA</span> <u> Learn how</u>
				</p>
			) : (
				<A
					className={styles.promo_text}
					href={'/truemed'}
					id={'promo-bar-hsahfa-link'}
				>
					<span style={{ paddingRight: '0.5ch' }}>Save up to 40% with HSA/FSA</span> <u> Learn how</u>
				</A>
			),
		})
	}

	if (props.promo_bar_text.includes('with code')) {
		items.length = 1
	}

	return (
		<div
			className={cx(styles.promo_bar, mounted ? styles.shown : '', {
				[styles.promo_bar_collapsed]: usableScrollDirection === 'down' || getClosed(),
			})}
			onTransitionEnd={() => setDoingAnimation(false)}
			id={'promo_bar'}
			style={promoStyle}
		>
			<ClientOnly>
				<div className={`${items.length === 1 ? styles.single_element : ''}`}>
					{items.length > 1 ? (
						<SlickSlider
							settings={{ ...settings, onInit: () => setMounted(true), initialSlide: 6, autoplay: true }}
							id={'promo-bar-slider'}
							showThumbs={false}
							items={[...items, ...items, ...items, ...items, ...items, ...items, ...items]}
							className={`${styles.slider}`}
						/>
					) : (
						items[0]?.component
					)}
				</div>
			</ClientOnly>

			<button
				id={'promo-bar-toggle-button'}
				className={styles.close_button}
				onClick={handleCloseClick}
			>
				<p className={'vh'}>close</p>
				<Icon
					color={'white'}
					icon={close}
				/>
			</button>
		</div>
	)
}

const getClosed = () => {
	if (typeof window !== 'undefined') {
		return !!window.sessionStorage.getItem('closedPromoBar')
	}
	return false
}
