import { CSSProperties, FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './ImageCarousel.module.scss'
import { ImageCarouselHandle, ImageCarouselProps, ThumbnailsProps } from './ImageCarousel.types'
import { Img } from 'components/basic/Img'
import { ImageProps, VideoProps } from 'components/_utils/utilityTypes'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { VideoGif } from 'components/basic/VideoGif'
import { useSwipe } from 'components/_hooks/useSwipe'
import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import { clamp } from 'components/_utils/mathUtils'
import { amSwipeImageCarousel } from 'events/amplitude'

export const ImageCarousel = forwardRef<ImageCarouselHandle, ImageCarouselProps>((props, ref) => {
	const { items } = props

	const sliderRef = useRef<HTMLUListElement>(null)

	const [currentIndex, setCurrentIndex] = useState(0)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			amSwipeImageCarousel(direction, props.id)
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(items.length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500
	)

	useImperativeHandle(ref, () => ({
		goToSlide: (index: number) => {
			setCurrentIndex(index)
		},
	}))

	const clampedIndex = clamp(currentIndex, 0, items.length - 1)

	return (
		<div className={styles.container}>
			<ul
				className={styles.media_stack}
				ref={sliderRef}
			>
				{items.map((item, index) => (
					<li
						key={index}
						className={styles.media_item}
						style={{ opacity: clampedIndex === index ? 1 : 0, visibility: clampedIndex === index ? 'visible' : 'hidden' }}
					>
						<MediaRendered
							{...item}
							active={clampedIndex === index}
						/>
					</li>
				))}
			</ul>

			<Thumbnails
				items={items}
				currentIndex={clampedIndex}
				setCurrentIndex={setCurrentIndex}
			/>
		</div>
	)
})

const MediaRendered: FC<StackItemProps & { active: boolean }> = (props) => {
	const { type, data, active } = props

	const [muted, setMuted] = useState(true)

	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (!videoRef.current) return
		if (type === 'video') {
			if (active) {
				videoRef.current.currentTime = 0
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [active])

	switch (type) {
		case 'image': {
			const _data = data as ImageProps
			return (
				<Img
					src={_data.src}
					alt={_data.alt}
					// objectFit={'cover'}
					dprHeight={1500}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.video_wrapper}>
					<video
						src={_data.src}
						poster={_data.poster}
						controls={false}
						autoPlay={false}
						muted={muted}
						loop
						ref={videoRef}
					/>
					<Button.Empty
						id={'video-mute'}
						className={styles.video_mute}
						onClick={() => setMuted((prev) => !prev)}
					>
						<Icon
							name={muted ? 'SoundOffLight' : 'SoundLight'}
							color={'white'}
						/>
					</Button.Empty>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<VideoGif
					src={_data.src}
					poster={_data.poster}
				/>
			)
		}
	}
}

const Thumbnails: FC<ThumbnailsProps> = (props) => {
	const { items, currentIndex, setCurrentIndex } = props
	if (items.length < 2) return null

	return (
		<ul
			className={styles.thumbnails}
			style={{ '--num-columns': items.length } as CSSProperties}
		>
			{items.map((item, index) => (
				<li
					key={index}
					className={styles.thumbnail}
					data-highlight={index === currentIndex}
				>
					<Button.Empty
						id={`thumbnail-${index}`}
						ariaLabel={`Go to slide ${index + 1}`}
						className={styles.thumbnail_button}
						onClick={() => setCurrentIndex(index)}
					>
						<div className={styles.thumbnail_dot} />
						<ThumbnailRendered
							{...item}
							index={index}
							active={currentIndex === index}
						/>
					</Button.Empty>
				</li>
			))}
		</ul>
	)
}

const ThumbnailRendered: FC<StackItemProps & { index: number; active: boolean }> = (props) => {
	const { type, data, index, active } = props

	switch (type) {
		case 'image': {
			const _data = data as ImageProps
			return (
				<Img
					src={_data.src}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={'cover'}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.thumbnail_video_container}>
					{/*<Icon*/}
					{/*	name={active ? 'PauseDark' : 'PlayDark'}*/}
					{/*	color={'white'}*/}
					{/*/>*/}
					<Img
						src={_data.poster}
						dprHeight={300}
						alt={`Thumbnail for slide ${index + 1}`}
						className={styles.thumbnail_image}
						objectFit={'cover'}
					/>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<Img
					src={_data.poster}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={'cover'}
				/>
			)
		}
	}
}
