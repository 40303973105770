import { FC } from 'react'
import styles from './ProductTitle.module.scss'
import { ProductTitleProps } from './types'
import { PricingInfo } from '../PricingInfo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { PriceManager } from 'prices'
import { FinancingInfo } from 'components/Financing/Financing'
import cx from 'classnames'
import { Visuals } from 'components/Phantom/_shop/Visuals'

/**
 * Connected version of the `<ProductTitle/>` component. Automatically subscribes to the shop store.
 */
export const ProductTitleConnected = observer(() => {
	const { shopStore, priceStore } = useRootStore()

	const selectors = shopStore.currentSelectors
	const selectorsProps = selectors.map((selectorKey) => shopStore.getSelectorProps(selectorKey)).filter((x) => !!x)
	const currentVisuals = shopStore.getCurrentVisuals()
	const currentSelections = shopStore.getCurrentSelections()

	const props: ProductTitleProps = {
		title: shopStore.podData.name,
		price: shopStore.podVariant.prices.price,
		comparePrice: shopStore.podVariant.prices.comparePrice,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
		currency: priceStore.currency,
	}

	return (
		<>
			<div className={styles.mobile_visual}>
				<Visuals
					visuals={currentVisuals}
					currentSelectorKey={selectorsProps[0].selectorKey}
					renderSingle={true}
					currentSelections={currentSelections}
				/>
			</div>

			<ProductTitle {...props} />
		</>
	)
})

/**
 * First shop page component. Displays the product title, price, and financing information.
 *
 * **Usually connected to the RootStore using the `<ProductTitleConnected/>` component**
 */
export const ProductTitle: FC<ProductTitleProps> = (props) => {
	const { title, price, comparePrice, currency, financingAmount } = props
	return (
		<section className={cx(styles.container, 'connected-title')}>
			<h2 className={styles.product_title}>{title}</h2>
			<PricingInfo
				price={PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}
				comparePrice={PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)}
				discountText={`${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
			/>
			<span className={styles.financing_info}>
				<FinancingInfo
					priceFormatted={financingAmount}
					className={styles.financing_info_inner}
				/>
			</span>
			<TruemedInfo className={styles.financing_info} />
		</section>
	)
}
