import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getLegKitVisuals = (): VisualProps => {
	return {
		type: 'image',
		associatedSelectorKeys: ['leg-kit'],
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+Extended.png',
			alt: 'Leg Ket display',
		},
	}
}
