import { MembershipPricing, PlanData } from 'shop/Shop.types'
import { getSubscriptionFeatureNiceName } from 'components/_utils/subscriptionUtils'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'

export const getStandardPlan = (currentPlanData: PlanData, standardPricing: MembershipPricing): CardSelectionItemProps => {
	if (Object.keys(currentPlanData).length && !currentPlanData.showStandardPlan) {
		return {
			id: 'autopilot_standard',
			key: 'autopilot_standard',
			type: 'standard',
			data: {
				subtitleMedium: currentPlanData.currentPriceMonthly,
				subtitle: currentPlanData.currentPrice,
				title: 'Current',
				checklist: currentPlanData.currentBenefits.map((b) => getSubscriptionFeatureNiceName(b)),
			},
		}
	}

	return {
		key: 'autopilot_standard',
		type: 'standard',
		data: {
			subtitleMedium: standardPricing.monthly + ' /mo',
			subtitleSmallest: standardPricing.yearly + ' billed annually',
			title: 'Standard',
			checklist: ['Automatic temperature', 'Sleep & health reports', 'Vibration & thermal alarms', 'Snoring detection & mitigation', 'Bi-annual maintenance filter delivery'],
			// product: standard,
		},
	}
}

export const getEnhancedPlan = (enhancedPricing: MembershipPricing): CardSelectionItemProps => {
	return {
		key: 'autopilot_enhanced',
		type: 'standard',
		data: {
			title: 'Enhanced',
			badge: 'Popular',
			subtitleMedium: enhancedPricing.monthly + ' /mo',
			subtitleSmallest: enhancedPricing.yearly + ' billed annually',
			checklist: [
				'Automatic temperature',
				'Sleep & health reports',
				'Vibration & thermal alarms',
				'Snoring detection & mitigation',
				'Bi-annual maintenance filter delivery',
				'Extended 5-year warranty',
			],
			// product: enhanced,
		},
	}
}
