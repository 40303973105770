import { FC, useEffect, useRef, useState } from 'react'
import { regionIsEU, RegionShort } from 'utils/internationalization'
import styles from './MobileNav.module.scss'
import cx from 'classnames'
import { Button } from 'components/basic/Button'
import { A } from 'components/basic/A'
import { useOnClickOutside } from 'components/_hooks/useOnClickOutside'
import { trackClickNavLink } from 'events/index'
import { amHamburgerABCLink } from 'events/amplitude'
import FocusTrap from 'focus-trap-react'
import { useRouter } from 'next/router'
import useABTestVariant from 'hooks/useABTestVariant'
import { navLinkCopyAB, navScienceAB } from 'ab_testing'
import { Img } from 'components/basic/Img'
import * as React from 'react'

export interface MobileNavProps {
	open: boolean
	useFocusTrap: boolean

	closeNav: () => void

	region: RegionShort
	setRegion: (region: RegionShort) => void
	onCountryPickerClick?: () => void

	ctaVisible: boolean

	cartIcon?: boolean
	cartClick?: () => void
	numberOfCartItems?: number
}

export const MobileNav: FC<MobileNavProps> = (props) => {
	const [focusTrap, setFocusTrap] = useState(false)
	const ref = useRef<HTMLDivElement>(null)
	const router = useRouter()
	const isNavbarAB = useABTestVariant(navLinkCopyAB, 1)
	const isNavScienceAB = useABTestVariant(navScienceAB, 1)

	useEffect(() => {
		setTimeout(() => setFocusTrap(props.open), 300) //delay to ensure focus trap goes on visible element
		const floatingEcapShortcut = document.getElementById('floating-modal')
		if (floatingEcapShortcut) {
			floatingEcapShortcut.style.display = props.open ? 'none' : 'block'
		}
	}, [props.open])

	useOnClickOutside(ref, props.closeNav)

	return (
		<FocusTrap active={props.open && props.useFocusTrap && focusTrap}>
			<div
				className={cx(styles.container, { [styles.container_open]: props.open })}
				ref={ref}
			>
				<Button
					unstyled
					id={'mobile-nav-close'}
					className={styles.close_button}
					onClick={props.closeNav}
					content={
						<svg viewBox="0 0 28 28">
							<path
								d="M6 6L22 22M6 22L22 6L6 22Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					}
				/>

				{props.cartIcon ? (
					<Button
						unstyled
						id={'mobile-cart'}
						className={styles.cart_button}
						onClick={props.cartClick}
						content={
							<>
								<Img
									src={'https://eightsleep.imgix.net/shop_cart_desktop.svg?v=1622155699'}
									alt={'Cart Icon'}
								/>
								{props.numberOfCartItems > 0 ? <div className={styles.cart_number}>{props.numberOfCartItems}</div> : null}
							</>
						}
					/>
				) : null}

				<div className={styles.inner}>
					<div className={cx(styles.links)}>{router.asPath.startsWith('/blog') ? getBlogMenus() : getLowerMobileNavLinks(props.region, isNavbarAB, isNavScienceAB)}</div>

					<div className={styles.spacer} />

					{/*<div className={styles.country_picker_container}>*/}
					{/*	<CountryPickerMobile*/}
					{/*		region={props.region}*/}
					{/*		setRegion={props.setRegion}*/}
					{/*		onClick={props.onCountryPickerClick}*/}
					{/*	/>*/}
					{/*</div>*/}
				</div>
			</div>
		</FocusTrap>
	)
}

function getBlogMenus() {
	return (
		<>
			<A
				href={'/blog/'}
				id={'mobile-nav-blog-home'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/blog/')
					trackClickNavLink('Mobile Subnav', 'Blog Home')
				}}
			>
				Blog Home
			</A>
			<A
				href={'/blog/sleep/'}
				id={'mobile-nav-blog-sleep'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/blog/sleep/')
					trackClickNavLink('Mobile Subnav', 'Blog Sleep')
				}}
			>
				Sleep
			</A>
			<A
				href={'/blog/wellness/'}
				id={'mobile-nav-blog-wellness'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/blog/wellness/')
					trackClickNavLink('Mobile Subnav', 'Blog Wellness')
				}}
			>
				Wellness
			</A>
			<A
				href={'/blog/fitness/'}
				id={'mobile-nav-blog-fitness'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/blog/fitness/')
					trackClickNavLink('Mobile Subnav', 'Blog Fitness')
				}}
			>
				Fitness
			</A>
			<A
				href={'/blog/science/'}
				id={'mobile-nav-blog-science'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/blog/science/')
					trackClickNavLink('Mobile Subnav', 'Blog Science')
				}}
			>
				Science
			</A>
		</>
	)
}

function getLowerMobileNavLinks(region: RegionShort, isNavbarAB: boolean, isNavScienceAB: boolean) {
	return (
		<>
			<A
				href={'/pod-cover'}
				id={'mobile-nav-explore-cover'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/pod-cover')
					trackClickNavLink('Mobile Subnav', 'Pod Cover')
				}}
			>
				{isNavbarAB ? 'Pod' : 'How it works'}
			</A>

			<A
				href={'/autopilot'}
				id={'mobile-nav-appo'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/autopilot')
					trackClickNavLink('Main', 'App')
				}}
			>
				Autopilot
			</A>

			{region !== 'au' && region !== 'uk' && !regionIsEU(region) && region !== 'ca' ? (
				isNavScienceAB ? (
					<A
						href={'/science'}
						id={'mobile-nav-science'}
						className={styles.link_large}
						onClick={() => {
							amHamburgerABCLink('/science')
							trackClickNavLink('Mobile Subnav', 'Science')
						}}
					>
						Science
					</A>
				) : (
					<A
						href={'/accessories'}
						id={'mobile-nav-accessories'}
						className={styles.link_large}
						onClick={() => {
							amHamburgerABCLink('/accessories')
							trackClickNavLink('Mobile Subnav', 'Accessories')
						}}
					>
						Accessories
					</A>
				)
			) : (
				<></>
			)}

			<A
				href={'/wall-of-love'}
				id={'mobile-nav-reviews'}
				className={styles.link_large}
				onClick={() => {
					amHamburgerABCLink('/wall-of-love')
					trackClickNavLink('Mobile Subnav', 'Reviews')
				}}
			>
				Reviews
			</A>
		</>
	)
}
