import { VisualProps } from 'components/Phantom/_shop/Visuals'
import { RegionShort } from 'utils/internationalization'

export const getSizeVisuals = (region: RegionShort, isPod3: boolean): VisualProps => {
	if (region === 'us' || region === '') {
		if (isPod3) {
			return getPod3SizeVisuals()
		}
		return getPod4SizeVisuals()
	}

	return getNonUSSizeVisuals()
}

export const getPod4SizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod4_mattress_size_full.png',
					alt: 'Full Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod4_mattress_size_queen.png',
					alt: 'Queen Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/pod4_size_king.jpg',
					alt: 'King Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/pod4_size_caliking.jpg',
					alt: 'Cali King Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod4_mattress_size_generic.png',
					alt: 'Pod 4 Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

export const getPod3SizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod3_mattress_size_full.png',
					alt: 'Full Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod3_mattress_size_queen.png',
					alt: 'Queen Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/pod3_size_king.jpg',
					alt: 'King Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/pod3_size_caliking.jpg',
					alt: 'Cali King Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod3_mattress_size_generic.png',
					alt: 'Pod 3 Size',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

export const getNonUSSizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod3_mattress_size_generic.png',
					alt: 'Pod 3 Size',
				},
				associatedSelections: [
					{
						key: 'pod-model',
						value: 'pod_3_cover_perfect',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/shop_pod4_mattress_size_generic.png',
					alt: 'Pod 4 Size',
				},
				associatedSelections: [
					{
						key: 'pod-model',
						value: '*',
					},
				],
			},
		],
	}
}
