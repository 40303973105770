import * as FB from './facebook'
import * as GA from './ga'
import * as AM from './amplitude'
import * as KY from './klaviyo'
import * as OTT from './ott'
import * as TW from './twitter'
import * as BG from './bing'

import { PageViewedType, ProactiveInteractionType } from 'constants/events'
import { removeHiddenCharsFromString } from 'utils/events'
import { sendPDPAbandonmentToKlaviyo, subscribeSMSToKlaviyo, subscribeToKlaviyo } from 'eight_api/aws'
import { CaptureLocation } from 'utils/events'
import { AffirmLocations, HomePageHeroCTAType, BundleInteractionType } from 'constants/events'
import { CartLineItem, LineItem } from 'cart/types'
import { createCartLineItemFromLineItem } from 'products/utils'
import { FormattedPrices } from 'prices/types'
import { loadExternalScript } from 'utils'
import { trackFriendbuyEcap } from './friendbuy'

export const updateEmailInLocalStorage = (email) => {
	localStorage.setItem('email', email)
}

export const trackPageView = () => {
	AM.amPageView()
	OTT.ottPageView()
	OTT.ottSiteVisit()
	FB.fbPageView()
	KY.kyPageView()
	TW.twPageView()
}

export const trackLinkClick = (to, htmlId) => {
	GA.gaTrackClickLink(to)
	AM.amClickLink(to, htmlId)
}

export const trackClickSocialMedia = (site) => {
	GA.gaClickSocialMedia(site)
	AM.amClickSocialMedia(site)
}

export const trackFooterLink = (str) => {
	AM.amClickFooter(str)
}

export const trackClickSupport = (str) => {
	AM.amClickSupport(str)
}

export const trackSubscribeEmail = async (email: string, location: CaptureLocation) => {
	GA.gaTrackEmailSubscribe(location)
	AM.amSubscribeEmail(email, location)
	AM.amAddEmailUserProperty(email)
	await subscribeToKlaviyo(email, location)
	OTT.ottEmailConversion(email)
	// send FB event only for all locations (Updated 03/23/2020)
	if (location !== CaptureLocation.CHECKOUT) {
		//FB.fbSubscribe(email, location);
		FB.fbSubscribeEmail(email, location)
		GA.GTMEmailSubscribe()
	}
	updateEmailInLocalStorage(email)
	await KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
}

export const trackSubscribeEmailAfterKlaviyoLambda = async (email: string, location: CaptureLocation) => {
	GA.gaTrackEmailSubscribe(location)
	AM.amSubscribeEmail(email, location)
	AM.amAddEmailUserProperty(email)
	OTT.ottEmailConversion(email)
	// send FB event only for all locations (Updated 03/23/2020)
	if (location !== CaptureLocation.CHECKOUT) {
		//FB.fbSubscribe(email, location);
		FB.fbSubscribeEmail(email, location)
		GA.GTMEmailSubscribe()
	}
	updateEmailInLocalStorage(email)
	KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
}

export const trackExpandQA = (question) => {
	AM.amClickFaq(question)
	GA.gaExpandFAQ(question)
	FB.fbClickFaq(question)
	KY.kyClickedFAQ(question)
}

export const trackCartOpen = () => {
	GA.gaExpandCart()
	AM.amOpenCart()
}

export const trackCartClose = () => {
	AM.amCloseCart()
}

export const trackVideoInteraction = () => {
	GA.gaPlayVideo()
	AM.amWatchedVideo()
}

export const trackAddToCart = (item: CartLineItem) => {
	FB.fbAddToCart(item)
	GA.gaAddToCart(item)
	KY.kyAddToCart(item)
	TW.twAddToCart(item)

	const productTitle = removeHiddenCharsFromString(item.productTitle)
	const variantTitle = removeHiddenCharsFromString(item.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: item.quantity,
		price: item.price,
		sku: parseInt(item.id),
		totalAmount: item.quantity * item.price,
		addMembership: false,
	}
	if ('addMembership' in item) {
		product.addMembership = item.addMembership as boolean
	}

	AM.amAddToCartV1(product, true)
}

export const AMamAddToCartV1 = (product: any) => {
	AM.amAddToCartV1(product, false)
}

export const trackRemoveFromCart = (item: LineItem, prices: FormattedPrices) => {
	const cartItem = createCartLineItemFromLineItem(item, prices)
	if (!cartItem) return

	const productTitle = removeHiddenCharsFromString(cartItem.productTitle)
	const variantTitle = removeHiddenCharsFromString(cartItem.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: cartItem.quantity,
		price: cartItem.price / 100,
		sku: parseInt(cartItem.id),
		totalAmount: cartItem.quantity * cartItem.price,
	}

	AM.removeFromCart(product)
}

export const trackUpdateCartItem = (item: LineItem, prices: FormattedPrices, action: string) => {
	const cartItem = createCartLineItemFromLineItem(item, prices)
	if (!cartItem) return

	const productTitle = removeHiddenCharsFromString(cartItem.productTitle)
	const variantTitle = removeHiddenCharsFromString(cartItem.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: cartItem.quantity,
		price: cartItem.price / 100,
		sku: parseInt(cartItem.id),
		totalAmount: cartItem.quantity * cartItem.price,
		old_quantity: action === 'Increase' ? cartItem.quantity - 1 : cartItem.quantity + 1,
	}

	AM.updateItemInCart(product, action)
}

export const trackTweetCardClick = (href) => {
	AM.amClickTwitterSlider(href)
}

export const trackViewTippy = (name) => {
	AM.amViewToolTip(name)
}

export const trackClickUrgencyArea = () => {
	AM.amClickUrgencyArea()
}

export const trackDisplayUrgencyTest = (days, zipcode, queen, king, caliking) => {
	AM.amShowUrgencyTest(days, zipcode, queen, king, caliking)
}

export const trackABtests = () => {
	AM.amABTests()
}

export const trackInitiateCheckout = (lineItems: CartLineItem[]) => {
	const itemNames = lineItems.map((li) => li.productTitle)
	const variantIds = lineItems.map((li) => li.variantId)
	AM.amInitiateCheckout(itemNames)
	GA.gaCheckoutInitiated()
	GA.GTMCheckoutInitiated()
	OTT.ottCheckoutInitiated(variantIds)
	FB.fbInitiateCheckout(itemNames)
	TW.twInitiateCheckout(lineItems)
	AM.amClickedButton('Checkout', 'cart-checkout-button')
}

export const trackFirstPageVisit = () => {
	try {
		const hasFirstLandingPageVisit = localStorage.getItem('firstVisitEpoch')
		if (!hasFirstLandingPageVisit) {
			AM.amFirstLandingPageVisit()
			localStorage.setItem('firstVisitEpoch', Date.now().toString())
		}
	} catch (e) {
		console.log(e)
	}
}

export const trackWarrantyPageVisit = () => {
	KY.kyVistedWarrantyPage()
}

export const trackCoverShopPageVisit = async (properties) => {
	const { email, code, amount, region } = properties
	const res = await sendPDPAbandonmentToKlaviyo(email, code, amount, region)
	return res
}

export const trackReturnPolicyPageVisit = () => {
	KY.kyVistedReturnPolicyPage()
}

// used for fb page views with time requirement for 1+ pages
export const trackPageViewed = (eventName: string, pageType: PageViewedType) => {
	FB.fbPageView()
	AM.amViewedPageSet(eventName, pageType)
}

export const trackAffirmLearnMoreClick = (location) => {
	let _location = location

	if (!Object.values(AffirmLocations).includes(_location)) {
		_location = AffirmLocations.OTHER
	}

	FB.fbClickAffirmLearnMore(_location)
	AM.amClickAffirmLearnMore(_location)
}

export const trackClickReviewStars = () => {
	AM.amClickedShopReviewStars()
}

export const trackPageLoaded = () => {
	AM.amPageLoaded()
}

export const trackToggleAccessory = (accessory) => {
	AM.amToggledFreeAccessory(accessory)
}

export const trackSelectedFreeAccessory = (accessory) => {
	AM.amSelectedFreeAccessory(accessory)
}

export const trackClosedPromoBar = () => {
	AM.amClosedPromoBar()
}

export const trackFeatureBlockInteraction = (feature) => {
	AM.amClickedFeatureBlock(feature)
}

export const trackInteractedDescriptionPaidAccessory = (accessory, actionType) => {
	AM.amInteractedPaidAccessoryDescription(accessory, actionType)
}

export const trackInteractedDescriptionFreeAccessory = (accessory, actionType) => {
	AM.amInteractedFreeAccessoryDescription(accessory, actionType)
}

export const trackClickHomePageHeroCTA = (cta: HomePageHeroCTAType) => {
	AM.amClickHomePageHeroCTA(cta)
}

export const trackClickSubnavChevron = (action: string) => {
	AM.amClickChevronSubNav(action)
}

export const trackOpenAccessoryModal = (accessory) => {
	AM.amOpenFreeAccessoryModal(accessory)
}

// used for amplitude user property to create cohorts based on blog and site
export const trackWebsiteVisit = () => {
	AM.trackHasVisitedSite()
}

export const selectedPodProduct = (variant: string, id: number) => {
	AM.amClickedSelectedPodProduct(variant, id)
}

export const trackClickedChat = () => {
	// check for kustomer body message
	try {
		const messageBody = document.querySelector('#kustomerIconMessageBody')
		if (messageBody) {
			AM.amTrackProactiveChatInteraction(ProactiveInteractionType.CLICKED)
		}
	} catch (e) {
		console.log(e)
	}

	AM.amClickedChat()
}

export const checkForViewedProactivecChat = () => {
	try {
		const messageBody = document.querySelector('#kustomerIconMessageBody')
		if (messageBody) {
			AM.amTrackProactiveChatInteraction(ProactiveInteractionType.VIEWED)
			return true
		}
	} catch (e) {
		console.log(e)
	}
	return false
}

export const clickCallUsCard = (option: string) => {
	AM.amClickCallUsCard(option)
}

export const clickedStudy = (studyTitle: string) => {
	AM.amClickedStudy(studyTitle)
}

export const compareSliderInteraction = () => {
	AM.amCompareSliderInteraction()
}

export const techSliderInteraction = (title: string) => {
	AM.amTechSliderInteraction(title)
}

export const clickedUpsellSlider = (name: string) => {
	AM.amClickedUpsellSlider(name)
}

export const trackBundleInteraction = (interaction: BundleInteractionType, bundleName: string) => {
	AM.amTrackBundleInteraction(interaction, bundleName)
}

export const trackAccessoryCardButtonClick = (to: string) => {
	AM.amTrackAccesoryCardV2ButtonClick(to)
}

export const trackProactiveChatInteraction = (interaction: ProactiveInteractionType) => {
	AM.amTrackProactiveChatInteraction(interaction)
}

export const trackClickedFilter = (filter: string) => {
	AM.amClickedGridFilter(filter)
}

export const trackClickedVideoOnReviewCard = (title: string, id: string) => {
	AM.amTrackClickedVideoOnReviewCard(title, id)
}

export const trackClickedCoverExplainerVideoPlay = () => {
	AM.amTrackClickedCoverExplainerVideoPlay()
}

export const trackAddMattressBundleToCart = (title: string, ids: number[], isPodPro: boolean, size: string, index: number) => {
	AM.amAddMattressBundleToCart(title, ids, isPodPro, size, index)
}

export const trackExpandMattressBundleSlideDown = (title: string, ids: number[], isPodPro: boolean, size: string, index: number) => {
	AM.amTrackMattressBundleSlideDown(title, ids, isPodPro, size, index)
}

// title is product title
export const trackAddAppleStyleUpsellToCart = (title: string, productId: number, variantId: number, price: number) => {
	AM.amAddAppleStyleUpsellToCart(title, productId, variantId)
	FB.fbAddToCart({
		productId: productId,
		productTitle: title,
		price: price * 100, // need to multiply for the price to be correct
	})
}

export const trackClickShopTab = (title: string) => {
	AM.amClickShopTab(title)
}

export const trackEmailCapClickBookDemo = (properties) => {
	AM.amEmailCapClickBookDemo(properties)
}

export const trackEmailCapQuizSelection = (choice: string, modalName: string) => {
	AM.amEmailCapQuizChoice(choice, modalName)
}

export const trackViewedEmailCap = (modalName: string) => {
	AM.amViewedEmailCap(modalName)
}

export const trackViewedAssistedModal = (modalName: string) => {
	AM.amViewedAssistedModal(modalName)
}

export const trackViewedSecondaryEmailCapModal = (modalName: string) => {
	AM.amViewedSecondaryEmailCapModal(modalName)
}

export const trackEmailModalButtonPress = (modalName: string, buttonPressed: string) => {
	AM.amEmailButtonModalPress(modalName, buttonPressed)
}

export const trackEmailModalFloatingButtonPress = () => {
	AM.amTrackEmailModalFloatingButtonPress()
}

export const trackEmailModalDismissFloatingButtonPress = () => {
	AM.amTrackEmailModalDismissFloatingButtonPress()
}

export const trackSubscribeViaEmailCap = async (properties, type?: string) => {
	const {
		email,
		location,
		answer,
		emailCapType,
		reasons,
		vanity,
		shouldUseVanityFlow,
		vanityQuote,
		vanityImage,
		vanityName,
		vanityDiscount,
		vanityLink,
		captureLocation,
		image,
		description,
		vanityCode,
		totalVanityDiscount,
		totalDiscount,
		discountCode,
	} = properties
	await subscribeToKlaviyo(email, location, {
		answer,
		emailCapType,
		reasons,
		vanity,
		shouldUseVanityFlow,
		vanityQuote,
		vanityImage,
		vanityName,
		vanityDiscount,
		vanityLink,
		captureLocation,
		image,
		description,
		vanityCode,
		totalVanityDiscount,
		totalDiscount,
		discountCode,
	})
	AM.amAddEmailUserProperty(email)
	AM.amSubscribedViaEmailCap(properties)
	GA.gaTrackEmailSubscribe(location)
	//FB.fbSubscribe(email, location);
	FB.fbSubscribeEmail(email, location)
	if (type !== 'checkout') updateEmailInLocalStorage(email)
	await KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
	GA.gaEmailCapSubscribeGoalCompletion()
	GA.GTMEmailSubscribe()
	BG.trackEmailSubscribe(email)
	loadExternalScript('https://tag.simpli.fi/sifitag/4eb86340-b517-013a-9a1b-0cc47abd0334', 'ott_email_subscribe')
}

export const trackQuizShown = () => {
	AM.amEcapQuizShown()
}

export const trackQuizAnswer = (answer: string, question: string) => {
	AM.amEcapQuizAnswered(answer, question)
}

export const trackSecondaryQuizEcapAnswer = (answer: string, question: string) => {
	AM.amSecondaryQuizEcapAnswered(answer, question)
}

export const trackSubscribeReferralCap = async (properties) => {
	const { email, name, referralCode } = properties
	const obj: any = {
		name,
		answer: name,
		description: 'referred friend',
		referral_modal: 'true',
	}
	await subscribeToKlaviyo(email, CaptureLocation.REFERRAL, obj)
	if (referralCode) {
		obj.referralCode = referralCode
	}
	await AM.amSubscribeReferralEmail(email, 'friendbuy ecap', referralCode)
	trackFriendbuyEcap(email)
}

export const trackSubscribeViaSMSCap = async (properties) => {
	const { phone, email, code, amount, region } = properties
	const smsRes = await subscribeSMSToKlaviyo(email, phone, code, amount, region)
	AM.amSubscribedViaSMSCap(properties)
	return smsRes
}

export const trackSelectReasonsNootYes = (action: string, reason: string, index: number) => {
	AM.amSelectReasonsNotYes(action, reason, index)
}

export const trackCompletedFollowUpQuestionEmailCap = (question: string, reasons: string[] | string, modalName: string) => {
	AM.amCompleteFollowUpQuestionEmailCap(question, reasons, modalName)
}

export const trackAddThermoCoverToCart = () => {
	FB.fbAddThermoCoverToCart()
}

export const trackOptInSMS = (choice: boolean) => {
	AM.amOptInSMS(choice)
}

export const trackClickPolicyLink = () => {
	AM.amClickTonePolicyLink()
}

export const trackClickFinancingModalAffirm = (location) => {
	// location should only be set to 'Cart' or null
	AM.amClickFinancingModalAffirm(location)
}

export const trackClickAssistedSaleCall = (location) => {
	AM.amClickAssistedSaleCall(location)
}

export const trackClickAssistedSaleChat = (location) => {
	AM.amClickAssistedSaleChat(location)
}

export const trackClickAssistedSaleDemo = (location) => {
	AM.amClickAssistedSaleDemo(location)
}

export const trackClickAccessoryVideoLink = (item, type) => {
	AM.amClickAccessoryVideoLink(item, type)
}

export const trackClickBlueAccessoryCard = (item) => {
	AM.amClickBlueAccessoryCard(item)
}

export const trackFinancingBannerButtonClick = (item) => {
	AM.amClickFinancingBannerButton(item)
}

export const trackCartUpsellLearnMoreInteraction = (item: string) => {
	AM.amInteractWithCartUpsellLearnMore(item)
}

export const trackClickContactUsItem = (contactMethod: string) => {
	AM.amClickedContactUsItem(contactMethod)
}

export const trackClickSleepFitnessProductCard = (item) => {
	AM.amClickSleepFitnessProductCard(item)
}

export const trackViewedVideoComfortSlider = (item, id) => {
	AM.amViewedVideoInComfortSlider(item, id)
}

export const trackViewedAllVideosInComfortSlider = () => {
	AM.amViewedAllVideosInComfortSlider()
}

export const trackClickedMattressComfortLayers = (item) => {
	AM.amSelectedMattressComfortLayers(item)
}

export const trackClickedComfortLayer = (name, layerTitle, arrow = null) => {
	AM.amClickedComfortLayer(name, layerTitle, arrow)
}

export const trackInteractedWithAllLayers = (mattress) => {
	AM.amInteractedWithAllComfortLayersForMattress(mattress)
}

export const trackInteractedWithAllMattressesInComfortLayers = () => {
	AM.amInteractedWithAllMattressesComfortLayers()
}

export const trackSwitchProductSize = (name) => {
	AM.amSwitchProduct(name)
}

export const trackClickAwardWinningPressLink = (source) => {
	AM.amClickAwardWinningPressLink(source)
}

export const trackClickNavLink = (type: AM.NavLinkType, target: string) => {
	AM.amClickNavLink(type, target)
}

export const trackClickSMSJoin = (location) => {
	AM.trackClickSMSJoin(location)
}

export const trackSMSCapture = (location) => {
	AM.trackSMSCapture(location)
}

export const trackCheckoutCanadaWarning = (itemNames: string[]) => {
	AM.amCheckoutCanadaWarning(itemNames)
}

export const trackClickedSleepCoachingEmailCap = () => {
	AM.amClickedSleepCoachingEmailCap()
}

export const trackClickedButton = (name: string, htmlId: string) => {
	AM.amClickedButton(name, htmlId)
}

export const updateUserUTMs = (utmMedium: string, utmSource: string) => {
	AM.amUpdateUserUTMs(utmMedium, utmSource)
}

export const emailCapShown = () => {
	AM.emailCapShown()
	KY.emailCapShown()
}
