import { FC, useEffect, useRef } from 'react'
import styles from './Visuals.module.scss'
import { CarouselVisualProps, ImageVisualProps, NewCarouselVisualProps, StackItemConnectedProps, StackVisualProps, VideoVisualProps, VisualProps, VisualsProps } from './Visuals.types'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { SelectorKey } from 'components/Phantom/_shop/Selectors'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { Carousel } from 'components/Phantom/_shop/Carousel'
import { CarouselHandle } from 'components/Phantom/_shop/Carousel/types'
import { Selections } from 'shop/Shop.types'
import { ImageCarousel, ImageCarouselHandle } from 'components/ImageCarousel'

const getCurrentVisual = (visuals: VisualProps[], currentSelectorKey: SelectorKey) => {
	const currentIndex = visuals.findIndex((visual) => visual.associatedSelectorKeys.includes(currentSelectorKey))
	if (currentIndex === -1) return 0
	return currentIndex
}

export const VisualsConnected = observer(() => {
	const { shopStore } = useRootStore()

	const currentVisuals = shopStore.getCurrentVisuals()
	const highlightedSelector = shopStore.highlightedSelector
	const currentSelections = shopStore.getCurrentSelections()

	return (
		<Visuals
			visuals={currentVisuals}
			currentSelectorKey={highlightedSelector}
			currentSelections={currentSelections}
		/>
	)
})

export const Visuals: FC<VisualsProps> = (props) => {
	const { visuals, currentSelectorKey, currentSelections, renderSingle = false } = props

	const currentIndex = getCurrentVisual(visuals, currentSelectorKey)

	if (renderSingle) {
		return (
			<div className={styles.single_visual}>
				<VisualRendered
					{...visuals[currentIndex]}
					currentSelections={currentSelections}
				/>
			</div>
		)
	}

	return (
		<div className={styles.rails}>
			<ul className={styles.container}>
				{visuals.map((visual, i) => (
					<li
						key={i}
						style={{ opacity: currentIndex === i ? 1 : 0, visibility: currentIndex === i ? 'visible' : 'hidden', pointerEvents: currentIndex === i ? 'auto' : 'none' }}
					>
						<VisualRendered
							{...visual}
							currentSelections={currentSelections}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

export const VisualRendered: FC<VisualProps> = (props) => {
	const { type, data } = props

	switch (type) {
		case 'image':
			return (
				<Img
					dprHeight={1000}
					className={styles.visual_image}
					src={data.src}
					alt={data.alt}
				/>
			)
		// TODO: implement 'video' type. Reference the ImageCarousel implementation
		case 'video':
		case 'video-gif':
			return (
				<VideoGif
					className={styles.visual_image}
					src={data.src}
					poster={data.poster}
				/>
			)
		case 'carousel':
			return <CarouselWrapped {...props} />
		case 'stack':
			return <StackVisual {...props} />
		case 'new_carousel':
			return <NewCarouselVisual {...props} />
	}
}

const NewCarouselVisual: FC<NewCarouselVisualProps> = (props) => {
	const { data, currentSelections } = props

	// TODO separate into hook

	const ref = useRef<ImageCarouselHandle>(null)
	const previousSelections = useRef<Selections>(currentSelections)

	useEffect(() => {
		if (!previousSelections || !currentSelections) {
			return
		}

		const associatedSelectionKeys = data.flatMap((item) => item.associatedSelections.map((it) => it.key))
		const uniqueSelections = associatedSelectionKeys.length === new Set(associatedSelectionKeys).size

		if (uniqueSelections) {
			// Go through each item in the carousel.
			// If the associated selection has changed, go to that slide.
			for (let i = 0; i < data.length; i++) {
				const item = data[i]
				if (!item.associatedSelections || item.associatedSelections.length === 0) {
					continue
				}

				const currentSelection = currentSelections[item.associatedSelections[0].key]
				const previousSelection = previousSelections.current[item.associatedSelections[0].key]

				if (currentSelection !== previousSelection) {
					ref.current?.goToSlide(i)
					break
				}
			}
		} else {
			// If not every item has a unique selection, then we have to do a deeper check to get the correct slide
			for (let i = 0; i < data.length; i++) {
				const item = data[i]
				if (!item.associatedSelections || item.associatedSelections.length === 0) {
					continue
				}

				const currentSelection = currentSelections[item.associatedSelections[0].key]

				if (currentSelection === item.associatedSelections[0].value) {
					ref.current?.goToSlide(i)
					break
				}
			}
		}

		previousSelections.current = { ...currentSelections }
	}, [currentSelections])

	return (
		<ImageCarousel
			id={props.associatedSelectorKeys.join('-')}
			ref={ref}
			items={data}
		/>
	)
}

const CarouselWrapped: FC<CarouselVisualProps> = (props) => {
	const { data, currentSelections } = props
	const ref = useRef<CarouselHandle>(null)
	const previousSelections = useRef<Selections>(currentSelections)

	useEffect(() => {
		if (!previousSelections || !currentSelections) {
			return
		}

		// Go through each item in the carousel.
		// If the associated selection has changed, go to that slide.
		for (let i = 0; i < data.length; i++) {
			const item = data[i]
			const currentSelection = currentSelections[item.associatedSelections[0].key]
			const previousSelection = previousSelections.current[item.associatedSelections[0].key]

			if (currentSelection !== previousSelection) {
				ref.current?.goToSlide(i)
				break
			}
		}

		previousSelections.current = { ...currentSelections }
	}, [currentSelections])

	return (
		<Carousel
			ref={ref}
			items={data.map((item, i) => {
				if (item.type === 'video') {
					const itemData = item.data as VideoVisualProps['data']
					return (
						<div key={i}>
							<VideoGif
								className={styles.visual_image}
								src={itemData.src}
								poster={itemData.poster}
							/>
						</div>
					)
				}

				const itemData = item.data as ImageVisualProps['data']
				return (
					<div key={i}>
						<Img
							dprHeight={1000}
							className={styles.visual_image}
							src={itemData.src}
							alt={itemData.alt}
							objectFit={'cover'}
						/>
					</div>
				)
			})}
		/>
	)
}

const StackVisual: FC<StackVisualProps> = (props) => {
	const { data, currentSelections } = props

	const isItemSelected = (item: StackItemConnectedProps) => {
		const associatedSelections = item.associatedSelections
		for (const selection of associatedSelections) {
			if (currentSelections[selection.key] !== selection.value && selection.value !== '*') {
				return false
			}
		}

		return true
	}

	const selectedArray = data.map((item) => isItemSelected(item))

	// If there are multiple stack items selected, filter out the wildcard selections.
	while (selectedArray.filter((i) => i).length > 1) {
		for (let i = 0; i < selectedArray.length; i++) {
			const selected = selectedArray[i]
			const item = data[i]
			if (selected) {
				// If the item is selected, check if it's a wildcard selection
				const associatedSelections = item.associatedSelections
				for (const selection of associatedSelections) {
					if (selection.value === '*') {
						// If it is, remove it from the selected array
						selectedArray[i] = false
					}
				}

				// Otherwise, leave it as is
			}
		}
	}

	return (
		<div className={styles.stack}>
			{data.map((item, i) => {
				const isSelected = selectedArray[i]

				if (item.type === 'video') {
					const itemData = item.data as VideoVisualProps['data']
					return (
						<div
							key={i}
							style={{ opacity: isSelected ? 1 : 0 }}
						>
							<VideoGif
								className={styles.visual_image}
								src={itemData.src}
								poster={itemData.poster}
							/>
						</div>
					)
				}

				const itemData = item.data as ImageVisualProps['data']
				return (
					<div
						key={i}
						style={{ opacity: isSelected ? 1 : 0 }}
					>
						<Img
							dprHeight={1000}
							className={styles.visual_image}
							src={itemData.src}
							alt={itemData.alt}
						/>
					</div>
				)
			})}
		</div>
	)
}
