import { FC } from 'react'
import styles from './Shop.module.scss'
import { ProductTitleConnected } from 'components/Phantom/_shop/ProductTitle'
import { ModalConnected } from 'components/Phantom/_shop/Modals/Modal'
import { StickyConnected } from 'components/Phantom/_shop/Sticky'
import { SideNavConnected } from 'components/Phantom/_shop/SideNav'
import { observer } from 'mobx-react'
import { VisualsConnected } from 'components/Phantom/_shop/Visuals'
import { SelectorsConnected } from 'components/Phantom/_shop/Selectors'
import { useRootStore } from 'components/_hooks/useRootStore'

export const ConnectedShop: FC = observer(() => {
	return (
		<main>
			<StickyConnected />
			<ModalConnected />
			<div
				className={styles.container}
				id="shop"
			>
				<div className={styles.container_inner}>
					<div className={styles.desktop_carousel_wrapper}>
						<VisualsConnected />
					</div>
					<div className={styles.sections}>
						<ProductTitleConnected />
						<SelectorsConnected />
					</div>
				</div>
				<div className={styles.side_nav_outer}>
					<div className={styles.side_nav_container}>
						<SideNavConnected />
					</div>
				</div>
			</div>
			{/*<Debug />*/}
		</main>
	)
})

const Debug = observer(() => {
	const { shopStore } = useRootStore()
	return (
		<div
			style={{
				position: 'fixed',
				bottom: '0',
				left: '0',
				backgroundColor: 'rgba(200,210,255,0.5)',
				color: 'black',
				fontSize: '0.75rem',
				maxWidth: '40vw',
				contain: 'paint',
				zIndex: 1000,
			}}
		>
			<pre>{JSON.stringify(shopStore.getCurrentSelections(), null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectedVariant, null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectedUpsells, null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectionKeys, null, 2)}</pre>
		</div>
	)
})
