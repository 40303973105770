import { ProductImageType } from './types'

export const podImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/pod2pro.jpg?v=1675462130',
		type: 'image',
		alt: '',
	},
	{
		src: 'https://eightsleep.imgix.net/p2p_2.jpg?v=1658752078',
		type: 'image',
		alt: '',
	},
	{
		src: 'https://eightsleep.imgix.net/pod_2_pro.jpg?v=1658951825',
		type: 'image',
		alt: '',
	},
	{
		src: 'https://eightsleep.imgix.net/Whats_in_the_box_pod_pro_2_mattress.png?v=1658881635',
		type: 'image',
		alt: '',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/carousel_phone.png?v=1670344340',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6ed98a8e267e492ea614244e78d617af.mp4',
		thumb: 'https://eightsleep.imgix.net/c_test_5.jpg?v=1646146453',
		placeholder: 'https://eightsleep.imgix.net/c_test_video_placeholder.jpg?v=1646154676',
		type: 'svideo',
	},
]

export const podProImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/slider_9c411a0d-433e-4bad-961c-62c6abbed31e.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - front view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/slider-1.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - angle view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/slider-2.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - side view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/Pro-item_thumb_-_large.png?v=1601475839',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/app_image.png?v=1647350730',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eight-eightsleep.s3.amazonaws.com/pod+pro+carousel+ball+compressed.mp4',
		alt: 'animation showing bowling ball bouncing on Eight Sleep Pod pro mattress',
		type: 'video',
	},
	{
		src: 'https://eight-eightsleep.s3.amazonaws.com/1447.mp4',
		alt: 'animation showing hand moving along side of Eight Sleep Pod Pro mattress',
		type: 'video',
	},
	{
		src: 'https://eight-eightsleep.s3.amazonaws.com/1389.mp4',
		alt: 'animation showing hand moving along top of Eight Sleep Pod Pro mattress',
		type: 'video',
	},
	{
		src: 'https://eight-eightsleep.s3.amazonaws.com/pod+pro+carousel+hub+compressed.mp4',
		alt: 'animation showing water reservoir in pod pro hub',
		type: 'video',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_mattress_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const pod3CoverImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod 3 Cover with Hub',
		src: 'https://eightsleep.imgix.net/pc3_standard.jpg?v=1694726114',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Above Your Mattress',
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/eacb30bf24fe4885806e01a3ed0a5e0c.mp4',
		type: 'video',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover box',
		src: 'https://eightsleep.imgix.net/witbnewcover.jpg?v=1694640681',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/phone-slide.jpg?v=1694031262',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const podProCoverPerfectImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod 3 Cover with Hub',
		src: 'https://eightsleep.imgix.net/pc3_pf.jpg?v=1694726332',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Above Your Mattress',
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/eacb30bf24fe4885806e01a3ed0a5e0c.mp4',
		type: 'video',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover box',
		src: 'https://eightsleep.imgix.net/witbnewcover.jpg?v=1694640681',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/phone-slide.jpg?v=1694031262',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const podProCoverPerfectImagesHoox: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod 3 Cover with Hub',
		src: 'https://eightsleep.imgix.net/pc3_1.jpg?v=1658749329',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/Mattress_carousel_c9930900-f74e-4c14-9316-967029462c18.jpg?v=1675461891',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/pc3_3.jpg?v=1658749329',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover box',
		src: 'https://eightsleep.imgix.net/cover_pod_3_00866448-db09-4b8b-9d6b-22b0d726fe29.jpg?v=1692175468',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/carousel_phone.png?v=1670344340',
		type: 'image',
	},
]

export const podProCoverPerfectImagesMembership: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub',
		src: 'https://eightsleep.imgix.net/cover-slide-0.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/cover-slide-2.png?v=1636030668',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-1.png?v=1636030669',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/app_image.png?v=1647350730',
		alt: '',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Zoom In',
		src: 'https://eightsleep.imgix.net/cover-slide-10.png?v=1636030668',
		type: 'image',
	},
	{
		// thumb: 'https://eightsleep.imgix.net/proapp.png?v=1646149476',
		type: 'image',
		src: 'https://eightsleep.imgix.net/proapp.png?v=1646149476',
		alt: '8+ pro membership',
		// video: 'https://eightsleep.imgix.net/proapp.png?v=1646149476'
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Top View',
		src: 'https://eightsleep.imgix.net/cover-slide-3.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Zoom In Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-4.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/cover-slide-5.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/cover-slide-9.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Delivery of the Eight Sleep Pod Pro Cover',
		src: 'https://eightsleep.imgix.net/thumb_15A.jpg?v=1603752224',
		type: 'image',
	},
]

export const podProCoverPerfectImagesWithMembership: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub',
		src: 'https://eightsleep.imgix.net/cover-slide-0.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/cover-slide-2.png?v=1636030668',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-1.png?v=1636030669',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/app_image.png?v=1647350730',
		alt: '',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Zoom In',
		src: 'https://eightsleep.imgix.net/cover-slide-10.png?v=1636030668',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Top View',
		src: 'https://eightsleep.imgix.net/cover-slide-3.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep app showing temperature auto-pilot',
		src: 'https://eightsleep.imgix.net/8bbe0fdb.jpg?v=1643888281',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Zoom In Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-4.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/cover-slide-5.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/cover-slide-9.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Delivery of the Eight Sleep Pod Pro Cover',
		src: 'https://eightsleep.imgix.net/thumb_15A.jpg?v=1603752224',
		type: 'image',
	},
]

export const podProCoverOriginalImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod 2 Cover with Hub',
		src: 'https://eightsleep.imgix.net/pc2.jpg?v=1694726113',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 2 Cover Above Your Mattress',
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/eacb30bf24fe4885806e01a3ed0a5e0c.mp4',
		type: 'video',
	},
	{
		alt: 'Eight Sleep Pod 2 Cover box',
		src: 'https://eightsleep.imgix.net/witbnewcover.jpg?v=1694640681',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/phone-slide.jpg?v=1694031262',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const podProCoverOriginalImagesMembership: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub',
		src: 'https://eightsleep.imgix.net/cover-slide-0.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/cover-slide-2.png?v=1636030668',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-1.png?v=1636030669',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/app_image.png?v=1647350730',
		alt: '',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Top View',
		src: 'https://eightsleep.imgix.net/cover-slide-3.png?v=1636030669',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/proapp.png?v=1646149476',
		type: 'image',
		alt: '8+ pro membership',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover with Hub Zoom In Side View',
		src: 'https://eightsleep.imgix.net/cover-slide-4.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Pro Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/cover-slide-5.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/cover-slide-9.png?v=1636030669',
		type: 'image',
	},
	{
		alt: 'Delivery of the Eight Sleep Pod Pro Cover',
		src: 'https://eightsleep.imgix.net/thumb_15A.jpg?v=1603752224',
		type: 'image',
	},
]

export const pod3CoverMemberUpgradeImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod 3 Cover box',
		src: 'https://eightsleep.imgix.net/Whats_in_the_box_member_carousel.jpg?v=1660107835',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/Mattress_carousel_c9930900-f74e-4c14-9316-967029462c18.jpg?v=1675461891',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/mp2.jpg?v=1658749665',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover video',
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/58de45206beb4a18ad602914b37f553e.mp4',
		type: 'video',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/carousel_phone.png?v=1670344340',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const pod3AnniversaryMemberUpgradeImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Anniversary Upgrade Package Items',
		src: 'https://eightsleep.imgix.net/13.jpg?v=1664478447',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Above Your Mattress',
		src: 'https://eightsleep.imgix.net/mp_1.jpg?v=1658749665',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover Hand Pressing Down',
		src: 'https://eightsleep.imgix.net/mp2.jpg?v=1658749665',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod 3 Cover video',
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/58de45206beb4a18ad602914b37f553e.mp4',
		type: 'video',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
	{
		alt: 'Eight Sleep The Air Pillow',
		src: 'https://eightsleep.imgix.net/anniversary_upgrade_slide_6.jpg?v=1664285912',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Air Lite Comforter',
		src: 'https://eightsleep.imgix.net/anniversary_upgrade_slide_7.jpg?v=1664285912',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Protector',
		src: 'https://eightsleep.imgix.net/anniversary_upgrade_slide_8.jpg?v=1664285911',
		type: 'image',
	},
]

export const sleepEssentialBundleImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep - Sleep Essentials Bundle showing pillows, sheets, and protector',
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/essentials-sheets-new_pillow_update.png',
		type: 'image',
	},
	{
		alt: 'The Air Pillow side view',
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-1.png',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Sheet Set folded top view',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resizeArtboard-1-copy-2_41af55ae-3c01-404f-a7fa-9f2b58865690.jpg?v=1582036637',
		type: 'image',
	},
	{
		alt: 'Pod Protector folded angled view',
		src: 'https://eightsleep.imgix.net/foundation_shop_thumb_1.jpg?309280',
		type: 'image',
	},
]

export const airLiteComforterImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/airlite_bright_4.jpg?v=1600974695',
		alt: 'Air Lite Comforter neatly folded',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/airlite_bright_3.jpg?v=1600974695',
		alt: 'Air Lite Comforter material close up',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/airlite_bright_2.jpg?v=1600974695',
		alt: 'Air Lite Comforter close up',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/airlite_bright_1.jpg?v=1600974695',
		alt: 'Air Lite Comforter folded up',
		type: 'image',
	},
	{
		alt: 'Air Lite Comforter blowing above Eight Sleep Pod Pro',
		src: 'https://eight-eightsleep.s3.amazonaws.com/comfort_air_video.mp4',
		type: 'video',
	},
]

export const lavenderSprayImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/edit-spray2.png?v=1589492945',
		alt: 'Lavender Sleep Spray relaxing pillow mist - standing',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/edit-spray_1.png?v=1589493218',
		alt: 'Lavender Sleep Spray being applied to pillow',
		type: 'image',
	},
	{
		alt: 'Lavender Sleep spray label - angled view - thumbnail',
		src: 'https://eightsleep.imgix.net/image6.jpg?v=1589487048',
		type: 'image',
	},
	{
		alt: 'Animation showing Eight Sleep Lavender Sleep Spray applied to The Air Pillow',
		src: 'https://eight-eightsleep.s3.amazonaws.com/sleepspray.mp4',
		type: 'video',
	},
]

export const hypervoltPlusImages: ProductImageType[] = [
	{
		alt: 'hypervolt standing front view',
		src: 'https://eightsleep.imgix.net/hyperice1_b7a72f17-9eb7-40f8-9b0d-e42eee0db9bf.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'cutaway x ray view showing inside of hypervolt ',
		src: 'https://eightsleep.imgix.net/hyperice2_c98597de-749a-4d6a-a6db-71e4d7113636.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'hyperice hypervolt - angled view',
		src: 'https://eightsleep.imgix.net/hyperice3_9e76fd04-5dd0-4f1f-8cc1-4ebb8b8cc7b4.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'hypervolt plus and various head components',
		src: 'https://eightsleep.imgix.net/hyperice4_41434c45-bf1a-4e86-9477-62d76216f504.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'athlete using hypervolt on leg while wearing athletic attire',
		src: 'https://eightsleep.imgix.net/hyperice5_62db77d9-e1b2-4d55-8a75-295caff76127.jpg?v=1692281424',
		type: 'image',
	},
]

export const hypervoltProImages: ProductImageType[] = [
	{
		alt: 'hypervolt standing front view',
		src: 'https://eightsleep.imgix.net/hypervolt_pro_1.jpg?v=1653036675',
		type: 'image',
	},
	{
		alt: 'hypervolt standing angled view',
		src: 'https://eightsleep.imgix.net/hypervolt_pro_2.jpg?v=1653036675',
		type: 'image',
	},
	{
		alt: 'athlete using hypervolt on leg while wearing athletic attire',
		src: 'https://eightsleep.imgix.net/hypervolt_pro_3.jpg?v=1653036675',
		type: 'image',
	},
	{
		alt: 'hypervolt 2 pro and various head components',
		src: 'https://eightsleep.imgix.net/hypervolt_pro_4.jpg?v=1653036675',
		type: 'image',
	},
	{
		alt: 'athlete holding hypervolt 2 pro while using mobile phone',
		src: 'https://eightsleep.imgix.net/hypervolt_pro_5.jpg?v=1653036675',
		type: 'image',
	},
]

export const hypericeVenomBack: ProductImageType[] = [
	{
		alt: 'man wearing Hyperice Venom Back muscle warmer',
		src: 'https://eightsleep.imgix.net/venom1_a5801fa2-2782-4260-a000-4ea8b89b726d.jpg?v=1692281423',
		type: 'image',
	},
	{
		alt: 'Hyperice Venom Back muscle warmer',
		src: 'https://eightsleep.imgix.net/venom2_2d458f4d-a221-41bf-ba97-3d0935107e9c.jpg?v=1692281423',
		type: 'image',
	},
	{
		alt: 'woman wearing Hyperice Venom Back muscle warmer',
		src: 'https://eightsleep.imgix.net/venom3_91816582-a2bf-45c3-b554-864bfef62c88.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'woman wearing Hyperice Venom Back muscle warmer',
		src: 'https://eightsleep.imgix.net/venom4_edc53938-6717-463c-ade7-41d9da9cba81.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'woman wearing Hyperice Venom Back muscle warmer',
		src: 'https://eightsleep.imgix.net/venom5_0e0a87f9-82e4-44ed-8460-0d7f8a6e3000.jpg?v=1692281424',
		type: 'image',
	},
]

export const normatecLegPulse: ProductImageType[] = [
	{
		alt: 'Normatek 2.0 muscle recovery boots - thumbnail',
		src: 'https://eightsleep.imgix.net/normatec1_a461ffaa-04a3-4dbf-bb8a-cd313e89075f.jpg?v=1692281424',
		type: 'image',
	},
	{
		alt: 'Normatek 2.0 muscle recovery boots - laid flat view',
		src: 'https://eightsleep.imgix.net/normatec2_1a536dd0-90ab-42c4-812d-8179f6d0c01f.jpg?v=1692281424',
		type: 'image',
	},
]

export const comfortBundle: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/comfort_bundle_product_carousel_1.jpg?v=1591052328',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-1.png',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/comforter_crop1_3.jpg?v=1590079066',
		alt: '',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Sheet Set folded top view',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resizeArtboard-1-copy-2_41af55ae-3c01-404f-a7fa-9f2b58865690.jpg?v=1582036637',
		type: 'image',
	},
]

export const nightTimeBundle: ProductImageType[] = [
	{
		alt: 'Air Lite Comforter next to Lavender Sleep Spray',
		src: 'https://eightsleep.imgix.net/nighttimebundlesliderimg.jpg?v=1600898698',
		type: 'image',
	},
	{
		alt: 'Lavender Sleep Spray in use',
		src: 'https://eightsleep.imgix.net/edit-spray_1_064670e6-8553-4b84-af14-0b267721c2ec.png?v=1600899547',
		type: 'image',
	},
	{
		alt: 'Air Lite Comforter blowing above Eight Sleep Pod Pro',
		src: 'https://eight-eightsleep.s3.amazonaws.com/comfort_air_video.mp4',
		type: 'image',
	},
]
export const theBaseImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/assets/Base_carousel_1.png',
		alt: 'The base',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/assets/Base_carousel_2.png',
		alt: 'The base',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/assets/Base_carousel_3.png',
		alt: 'The base',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/assets/Base_carousel_5.png',
		alt: 'The base',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/assets/Base_carousel_6_1.jpg',
		alt: 'The base',
		type: 'image',
	},
]
export const carbonAirPillowImages: ProductImageType[] = [
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-1.png',
		alt: 'Eight Sleep The Air Pillow angled view',
		type: 'image',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-2.png',
		alt: 'Eight Sleep The Air Pillow top view - half of pillow visible',
		type: 'image',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-3.png',
		alt: 'Eight Sleep The Air Pillow side view with cover - thumbnail',
		type: 'image',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-air-pillow-4.png',
		alt: 'Eight Sleep Pod Mattress with The Air Pillow in bedroom scene',
		type: 'image',
	},
]

export const podSheetDarkGraySetImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/sheets-03.png?v=1695051873',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/sheets-02.png?v=1695051873',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/sheets-04.png?v=1695051873',
		alt: '',
		type: 'image',
	},
]

export const podSheetSetImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep Pod Sheet Set folded angled view',
		src: 'https://eightsleep.imgix.net/sheets_bright_4.jpg?v=1600974710',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Sheet Set multiple views',
		src: 'https://eightsleep.imgix.net/sheets_bright_2.jpg?v=1600974710',
		type: 'image',
	},
	{
		alt: 'Eight Sleep Pod Sheet Set multiple views',
		src: 'https://eightsleep.imgix.net/item_thumb_-_large-2_173fd690-53cc-41a5-a060-dea7b03270d7.jpg?v=1604337054',
		type: 'image',
	},
]

export const gravityBlanketImages: ProductImageType[] = [
	{
		alt: 'Eight Sleep branded Gravity Blanket blue angled view',
		src: 'https://eightsleep.imgix.net/gb-nb-2.jpg?v=1608239455',
		type: 'image',
	},
	{
		alt: 'Gravity Blanket',
		src: 'https://eightsleep.imgix.net/gb-nb-1.jpg?v=1608239455',
		type: 'image',
	},
	{
		alt: 'Gravity Blanket',
		src: 'https://eightsleep.imgix.net/gb-nb-3.jpg?v=1608239455',
		type: 'image',
	},
]

export const foundationImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/foundation_features_bg_desktop.jpg?309969',
		alt: 'Pod on Pod Foundation - angled view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/new_foundation_full_2.jpg?318384',
		alt: 'Foundation by itself with partially folded slats - angled view',
		type: 'image',
	},
	{
		alt: 'Pod Foundation corner close up shot',
		src: 'https://eightsleep.imgix.net/corner-3x.jpg?318717',
		type: 'image',
	},
	{
		alt: 'Pod Foundation front view',
		src: 'https://eightsleep.imgix.net/foundation_accessory_main.jpg?318380',
		type: 'image',
	},
]

export const protectorImages: ProductImageType[] = [
	{
		alt: 'Pod Protector folded angled view',
		src: 'https://eightsleep.imgix.net/foundation_shop_thumb_1.jpg?309280',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/assets/1_9de53fb1-081e-4fb1-98e0-9a3bc686ca4d.jpg',
		type: 'image',
		alt: 'folded Eight Sleep Pod Protector',
	},
	{
		alt: 'Grey mattress on Pod Foundation - angled view',
		src: 'https://eightsleep.imgix.net/foundation_shop_thumb_4_Cropped.jpg?v=1594931456',
		type: 'image',
	},
]

export const pillowImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/pillow_shop_thumb_1.jpg?3092910',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/2_copy.jpg?v=1582670568',
		alt: '',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/3_9a6ef54b-b4e9-4d21-934b-fc6f28e78a0c.jpg?v=1582670568',
		alt: '',
		type: 'image',
	},
	{
		type: 'image',
		src: 'https://eightsleep.imgix.net/4_copy.jpg?v=1582670568',
		alt: 'Eight Sleep Pod Mattress with The Air Pillow in bedroom scene',
	},
]

export const sleepFitnessKitImages: ProductImageType[] = [
	{
		type: 'image',
		alt: 'Thorne Supplements - all 3 products in one bundle',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resizehero_c08c3a4b-8d1a-4c55-909a-9215496f7891.jpg?v=1582036637',
	},
	{
		type: 'image',
		alt: 'Thorne Supplements - Magnesium Citrate',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resizehero-copy_60e8d491-add6-4c10-a7ce-72cb934bff96.jpg?v=1582036637',
	},
	{
		type: 'image',
		alt: 'Thorne supplements - Melaton 3 melatonin supplement',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resize3-copy_a0d62d16-1de8-4697-b076-3fec3ac49ece.jpg?v=1582036637',
	},
	{
		type: 'image',
		alt: 'Thorne Supplements - Recovery Pro ',
		src: 'https://eightsleep.imgix.net/Eight-bed-sheets-resize3_b94a9bfa-8666-4251-8072-9d22bb7dc4d9.jpg?v=1582036637',
	},
]

export const encasementImages: ProductImageType[] = [
	{
		type: 'image',
		alt: 'The Pod Encasement rolled up',
		src: 'https://eightsleep.imgix.net/encasement1.jpg?v=1661776933',
	},
	{
		type: 'image',
		alt: 'The Pod Encasement rolled up alternate view',
		src: 'https://eightsleep.imgix.net/encasement2.jpg?v=1661776933',
	},
	{
		type: 'image',
		alt: 'The Pod encasement on a mattress',
		src: 'https://eightsleep.imgix.net/encasement3.jpg?v=1661776933',
	},
]

export const podProMaxImages: ProductImageType[] = [
	{
		src: 'https://eightsleep.imgix.net/slider_9c411a0d-433e-4bad-961c-62c6abbed31e.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - front view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/slider-1.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - angle view',
		type: 'image',
	},
	{
		src: 'https://eightsleep.imgix.net/slider-2.jpg?v=1643996271',
		alt: 'Eight Sleep Pod Pro in modern bedroom - side view',
		type: 'image',
	},
	{
		alt: '',
		type: 'image',
		src: 'https://eightsleep.imgix.net/carousel_pod_max_layers_1200.jpg?v=1613028487',
	},
	{
		src: 'https://eightsleep.imgix.net/app_image.png?v=1647350730',
		alt: '',
		type: 'image',
	},
	{
		type: 'image',
		alt: 'animation showing bowling ball bouncing on Eight Sleep Pod pro mattress',
		src: 'https://eight-eightsleep.s3.amazonaws.com/pod+pro+carousel+ball+compressed.mp4',
	},
	{
		type: 'image',
		alt: 'animation showing hand moving along side of Eight Sleep Pod Pro Max mattress',
		src: 'https://eight-eightsleep.s3.amazonaws.com/1447.mp4',
	},
	{
		type: 'image',
		alt: 'animation showing hand moving along top of Eight Sleep Pod Pro mattress',
		src: 'https://eight-eightsleep.s3.amazonaws.com/1389.mp4',
	},
	{
		type: 'image',
		alt: 'animation showing water reservoir in pod pro hub',
		src: 'https://eight-eightsleep.s3.amazonaws.com/pod+pro+carousel+hub+compressed.mp4',
	},
	{
		src: 'https://eightsleep.imgix.net/shop-page-8.jpg?v=1589322901',
		alt: 'Eight Sleep Pod Pro person using app to set temperature',
		type: 'image',
	},
]

export const podProCoverMembershipImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/cover-slide-0.png?v=1636030669',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/05dd2fb0.jpg?v=1648566051',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/c_test_2.jpg?v=1646146453',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/c_test_3.jpg?v=1646146453',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/c_test_4.jpg?v=1646146453',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/d90abd3ec97542d8a954948604185458.mp4',
		thumb: 'https://eightsleep.imgix.net/c_test_5.jpg?v=1646146453',
		placeholder: 'https://eightsleep.imgix.net/c_test_video_placeholder.jpg?v=1646154676',
		type: 'svideo',
	},
]

export const podProMembershipImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Mattress_Shop_-_Pod_2_Pro.jpg?v=1675461883',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/pod3_2.jpg?v=1658752455',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/pod3_3-1.jpg?v=1658752454',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Whats_in_the_box_mattress.png?v=1658881635',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/carousel_phone.png?v=1670344340',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_mattress_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const podProMaxMembershipImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/pod3max.jpg?v=1675462131',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/pod3_2.jpg?v=1658752455',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/pod3_3-2.jpg?v=1658752454',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Whats_in_the_box_mattress.png?v=1658881635',
		type: 'image',
	},
	{
		alt: 'Eight Sleep App Being Used To Change Temperature',
		src: 'https://eightsleep.imgix.net/carousel_phone.png?v=1670344340',
		type: 'image',
	},
	{
		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
		thumb: 'https://eightsleep.imgix.net/original_blue_cover_placeholder.jpg?v=1672386791',
		placeholder: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		type: 'svideo',
	},
]

export const sleepCoachingImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/performance-report.jpg?v=1649936000',
		type: 'image',
	},
]

export const canopyHumidifierImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/humidifier_1.jpg?v=1651735687',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/humidifier_2.jpg?v=1651735687',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/humidifier_3.jpg?v=1651735687',
		type: 'image',
	},
]

export const duvetCoverImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_1.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_1.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_2.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_2.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_3.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_3.jpg?v=1651735687',
		type: 'image',
		variant: 'blush-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_4.jpg?v=1651763322',
		type: 'image',
		variant: 'blush-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_blush_4.jpg?v=1651763322',
		type: 'image',
		variant: 'blush-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_1.jpg?v=1651735687',
		type: 'image',
		variant: 'buttermilk-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_1.jpg?v=1651735687',
		type: 'image',
		variant: 'buttermilk-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_2.jpg?v=1651735687',
		type: 'image',
		variant: 'buttermilk-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_2.jpg?v=1651735687',
		type: 'image',
		variant: 'buttermilk-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_3_fb211120-0853-4e25-ad06-27b83ce906d8.jpg?v=1651816917',
		type: 'image',
		variant: 'buttermilk-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_3_fb211120-0853-4e25-ad06-27b83ce906d8.jpg?v=1651816917',
		type: 'image',
		variant: 'buttermilk-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_4.jpg?v=1651763322',
		type: 'image',
		variant: 'buttermilk-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_buttermilk_4.jpg?v=1651763322',
		type: 'image',
		variant: 'buttermilk-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_1.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_1.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_2.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_2.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_3.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_3.jpg?v=1651735687',
		type: 'image',
		variant: 'coal-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_4.jpg?v=1651763322',
		type: 'image',
		variant: 'coal-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_coal_4.jpg?v=1651763322',
		type: 'image',
		variant: 'coal-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_1.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_1.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_2.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_2.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_3.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_3.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_4.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/duvet_moon_4.jpg?v=1651763154',
		type: 'image',
		variant: 'moon-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_1_12975947-bf26-4235-93ee-347f0041c60d.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_1_12975947-bf26-4235-93ee-347f0041c60d.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_2_dc940db7-6132-4dd2-9a68-7c94aace579e.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_2_dc940db7-6132-4dd2-9a68-7c94aace579e.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_LS2_e144af5d-7b7e-4e51-9adf-51d3a55e329f.jpg?v=1661971352',
		type: 'image',
		variant: 'taupe-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_LS2_e144af5d-7b7e-4e51-9adf-51d3a55e329f.jpg?v=1661971352',
		type: 'image',
		variant: 'taupe-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_LS1_8f9a4bf9-1be5-4fd1-83f4-0efce2a89e03.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Taupe_LS1_8f9a4bf9-1be5-4fd1-83f4-0efce2a89e03.jpg?v=1661971351',
		type: 'image',
		variant: 'taupe-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_1_773ef9d2-d3c8-4f1b-b31b-448d818cde0f.png?v=1661971343',
		type: 'image',
		variant: 'denim-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_1_773ef9d2-d3c8-4f1b-b31b-448d818cde0f.png?v=1661971343',
		type: 'image',
		variant: 'denim-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_2_910cf58a-51cd-4ffe-9ed2-ddd331944922.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_2_910cf58a-51cd-4ffe-9ed2-ddd331944922.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_LS2_c846078a-f679-44a0-a88a-c9c216554ef8.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_LS2_c846078a-f679-44a0-a88a-c9c216554ef8.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_LS1_dd3f3654-288d-4caf-9057-0768b5f2ef10.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Denim_LS1_dd3f3654-288d-4caf-9057-0768b5f2ef10.jpg?v=1661971343',
		type: 'image',
		variant: 'denim-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_1_f734f482-93e1-414a-a28d-1783566fa125.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_1_f734f482-93e1-414a-a28d-1783566fa125.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_2_0642867a-1497-41e2-bd86-19f37ba36ce2.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_2_0642867a-1497-41e2-bd86-19f37ba36ce2.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_LS2_b2ddff69-3fa5-4475-b289-279218713981.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_LS2_b2ddff69-3fa5-4475-b289-279218713981.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-king',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_LS1_1ebfc160-eb4c-4fa4-9360-686a3237db91.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-queen',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Sunday-Citizen_Premium-Bamboo-Duvet-Cover_Sage_LS1_1ebfc160-eb4c-4fa4-9360-686a3237db91.jpg?v=1661971329',
		type: 'image',
		variant: 'sage-king',
	},
]

export const mattressImages: ProductImageType[] = [
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Frame_2609122.jpg?v=1684336181',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Frame_2609122_1.jpg?v=1684336181',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Byrum-EightSleep-Shot2-0114-V2.jpg?v=1684336182',
		type: 'image',
	},
	{
		alt: '',
		src: 'https://eightsleep.imgix.net/Frame_2609138.jpg?v=1684336182',
		type: 'image',
	},
]
