import styles from './Pod4FAQ.module.scss'
import SlideDown from 'components/SlideDown'
import { Pod4FAQProps } from './types'

export const Pod4FAQ = ({ faq }: Pod4FAQProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<div className={styles.title}>FAQs</div>
				<div className={styles.faqs}>
					{faq.map((qa, i) => (
						<SlideDown
							top={<p className={styles.question}>{qa.question}</p>}
							key={i}
							className={styles.question_container}
							inverted={true}
						>
							<span className={styles.answer}>{qa.answer}</span>
						</SlideDown>
					))}
				</div>
			</div>
		</div>
	)
}
