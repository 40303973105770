import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getModelVisuals = (modelValue: string): VisualProps => {
	switch (modelValue) {
		case 'pod_3_cover_perfect':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/new_carousel_pod3_gif.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod+3+gif+poster.jpg',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_combined_pod3.png',
							alt: 'Pod 4',
						},
						associatedSelections: [],
					},
				],
			}
		case 'pod_4':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/new_carousel_pod4_gif.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod+4+gif+poster.jpg',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_combined_pod4.png',
							alt: 'Pod 4',
						},
						associatedSelections: [],
					},
				],
			}
		default:
		case 'pod_4_ultra':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/eight_sleep_Pod4_ultra_v01_1.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/eight_sleep_Pod4_ultra_v01_1_poster.png',
						},
						associatedSelections: [],
					},
					// {
					// 	type: 'video',
					// 	data: {
					// 		src: 'https://d115sb6i1ixllw.cloudfront.net/assets/pod_4_launch_square_subtitles.mp4',
					// 		poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod+4+launch+poster.png',
					// 	},
					// 	associatedSelections: [],
					// },
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_combined_pod4ultra.png',
							alt: 'Pod 4 Ultra',
						},
						associatedSelections: [],
					},
				],
			}
	}
}
