import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'

export const Pod3: CardSelectionItemProps = {
	key: 'pod_3_cover_perfect',
	type: 'standard',
	data: {
		title: 'Pod 3',
		subtitleTop: 'Cover + Hub',
		subtitleSmallest: 'Includes the below:',
		checklist: ['Previous generation technology'],
	},
}

export const Pod4: CardSelectionItemProps = {
	key: 'pod_4',
	type: 'standard',
	data: {
		title: 'Pod 4',
		badge: 'New',
		subtitleTop: 'Cover + Hub',
		subtitleSmallest: 'Includes the below:',
		checklist: ['2x more cooling power', 'Silent performance', 'Enhanced comfort', 'Tap to control', 'Snoring detection'],
	},
}

export const Pod4Ultra: CardSelectionItemProps = {
	key: 'pod_4_ultra',
	type: 'standard',
	data: {
		title: 'Pod 4 Ultra',
		badge: 'New',
		subtitleTop: 'Cover + Hub + Base',
		subtitleSmallest: 'Everything in Pod 4, plus:',
		checklist: ['Sleeping, reading, and custom positioning', 'Snoring mitigation'],
	},
}

export const getModelOptions = (): CardSelectionItemProps[] => {
	return [Pod3, Pod4, Pod4Ultra]
}
